import {
  Button,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
  Image,
} from "react-bootstrap";
import ArrowRightIcon from "../app/assets/images/arrow-right.svg";

import logo from '../../src/app/assets/images/logo.png'

function HomeHeader() {
  return (
    <Navbar collapseOnSelect expand="lg" className="headbg">
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} className="logoimg" alt="brand"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#">Home</Nav.Link>
            <Nav.Link href="/#about">About</Nav.Link>
            <Nav.Link href="/#services">Services</Nav.Link>
            {/* <Nav.Link href="#">Work</Nav.Link> */}
            <Nav.Link target="_blank" href="https://1techspot.com/blog">Blog</Nav.Link>
            <Nav.Link href="/#contact" className="contbtnlink headerbtn">
                <Button variant="outline-secondary" id="button-addon2">Contact Us <span className="ms-2 cntbtnbg"><Image src={ArrowRightIcon} /></span>
                </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HomeHeader;
